import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import RTLLayout from "layouts/rtl";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import SignIn from "views/auth/signIn";

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <ThemeEditorProvider>
        <HashRouter>
          <Switch>
            <Route path={'/sign-in'} component={SignIn} />
            {/* <Route path={`/auth`} component={AuthLayout} /> */}
            {localStorage.getItem("token") && 
            <Route path={`/admin`} component={AdminLayout} />
            }
            <Route path={`/rtl`} component={RTLLayout} />
            {/* <Redirect from='/' to='/sign-in' /> */}
            {localStorage.getItem("token") ? <Redirect from='/' to='/admin/app-users' /> : <Redirect from='/' to='/sign-in/' />}
            {/* {token ? <Redirect from='/sign-in' to='/admin/dashboard' /> : <Redirect from='/admin' to='/sign-in/' />} */}
          </Switch>
        </HashRouter>
      </ThemeEditorProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
