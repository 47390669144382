import React from "react";
import { NavLink, useHistory, withRouter, useLocation } from "react-router-dom";
import axios from 'axios';
// Chakra imports
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// // Custom components
// import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
// import illustration from "assets/img/auth/auth.png";
// import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

function SignIn() {
  const history = useHistory();
  const location = useLocation();
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const handleClick = () => setShow(!show);
  
//create handle submit function and validate email and password and then call login api
  const handleSubmit = async() => {
    if(email === ''){
      setEmailError('Email is required');
    }else{
      setEmailError('');
    }
    if(password === ''){
      setPasswordError('Password is required');
    }else{
      setPasswordError('');
    }
    if(email !== '' && password !== ''){
      setIsSubmitting(true);
      await axios.post('https://admin.researchvia.online/web/v1/login', {
        email: email,
        password: password
      }).then(async (response) => {
        setIsSubmitting(false);
        if(response.status === 200){
          localStorage.setItem('token', response.data.token);
          if(localStorage.getItem('token') !== null){
          if(location.state !== undefined){
            await history.replace(location.state.from);
          }else{
            await history.replace('/');
          }
          }
          // window.location.reload();
          // window.location.href = '#/admin/app-users';
          // console.log(response.data.token);
      }
      }
      ).catch((error) => {
        // console.log(error.response.data.message);
        setIsSubmitting(false);
        alert(error.response.data.message);   
        setEmail('');
        setPassword('');   
      }
      );
  }
  };

  return (
    <DefaultAuth>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign In
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <FormControl isRequired isInvalid onSubmit={handleSubmit}>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='mail@email.com'
              fontWeight='500'
              size='lg'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <FormHelperText color={'red.500'} mb='24px'>
              {emailError}
            </FormHelperText>
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                isRequired={true}
                fontSize='sm'
                placeholder='Min. 8 characters'
                size='lg'
                type={show ? "text" : "password"}
                variant='auth'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement display='flex' alignItems='center' mt='4px' >
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <FormHelperText color={'red.500'} mb='24px'>
              {passwordError}
            </FormHelperText>
            <Flex justifyContent='space-between' align='center' mb='24px'>
            </Flex>
            <Button
            loadingText="Signing In..."
            _loading={{ opacity: "0.8" }}
            isLoading={isSubmitting}
              onClick={handleSubmit}
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'>
              Sign In
            </Button>
          </FormControl>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default withRouter(SignIn);
