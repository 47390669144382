// Chakra imports
import { Box } from "@chakra-ui/react";
import React from "react";
import { Table, Select } from 'antd';
import axios from "axios";
import { formatDate } from "contexts/dateFormate";



function Settings() {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 50,
  });
  var names = [];
  var numbers = [];
  const column = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      filterSearch: true,
      filterMultiple: true,
      filters: names
    },
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
      width: 150,
      sorter: (a, b) => a.number - b.number,
      sortDirections: ['descend', 'ascend'],
      onFilter: (value, record) => record.number.indexOf(value) === 0,
      filterSearch: true,
      filterMultiple: true,
      filters: numbers
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 230,
      // fixed: 'left',
    },
    {
      title: 'Aadhhar Number',
      dataIndex: 'aadhar',
      key: 'aadhar',
      width: 150,
      // fixed: 'left',
    },
    {
      title: 'Pan Number',
      dataIndex: 'pan',
      key: 'pan',
      width: 150,
      // fixed: 'left',
    },
    {
      title: 'Date of Birth',
      dataIndex: 'dob',
      key: 'dob',
      width: 150,
      // fixed: 'left',
    },
    {
      title: 'Nominee Name',
      dataIndex: 'nominee_name',
      key: 'nominee_name',
      width: 150,
      // fixed: 'left',
    },
    {
      title: 'KYC Status',
      dataIndex: 'kyc_status',
      key: 'kyc_status',
      width: 150,
      sorter: (a, b) => a.kyc_status.length - b.kyc_status.length,
      sortDirections: ['descend', 'ascend'],
      //render it with option with status
      render: (text, record) => 
      <Select defaultValue={text} style={{ width: 120 }} 
      onChange={(value) => handleStatusChange(value, record.id)}
      >
          <Select.Option value="KYC Verified">KYC Verified</Select.Option>
          <Select.Option value="KYC Rejected">KYC Rejected</Select.Option>
      </Select>
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 190,
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      sortDirections: ['descend', 'ascend'],
      // render : (text) => <span>{new Date(text).toLocaleString()}</span>
      render: (text, record) => (
        <span>{formatDate(record.created_at)}</span>
      )
    },
      // {
      // title: 'Action',
      // dataIndex: 'action',
      // key: 'action',
      // width: 150,
      // fixed: 'right',
      // render: (text, record) => (
      //     <span>
      //         <a style={{ marginRight: 16 }}>View</a>
      //         <a>Delete</a>
      //     </span>
      //     ),
      // },
  ]
  const [sorter, setSorter] = React.useState({});
  const [filter, setFilter] = React.useState({});
  const [columns, setColumns] = React.useState(column);
  const [status, setStatus] = React.useState("");

    const handleStatusChange = (value, id) => { 
        // console.log(value, id);
        setStatus(value);
        setLoading(true);
        axios.post("https://admin.researchvia.online/web/v1/updateKycStatus",{id: id,kyc_status: value,},
                {headers: { "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token")},
                })
            .then((res) => {
                // console.log("res", res);
                getAllKycRequests();
                // alert(res.data.message);
            })
            .catch((err) => {
              if(err.response.status === 401){
                localStorage.removeItem('token');
                  window.location.href = '/'
              }
                console.log(err);
                setLoading(false);
            });
    };
  
  const handleTableChange = (pagination, filters, sorter) => {
    // console.log(pagination, filters, sorter);
    setPagination(pagination);
    setSorter(sorter);
    setFilter(filters);
  };

  const getAllKycRequests = () => {
    setLoading(true);
    axios
      .get("https://admin.researchvia.online/web/v1/getKycRequests", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log("res", res);
        setData(res.data.result);
        res.data.result.map((item) => {
          names.push({text: item.name, value: item.name});
          numbers.push({text: item.number, value: item.number});
        });
        setLoading(false);
      })
      .catch((err) => {
        if(err.response.status === 401){
          localStorage.removeItem('token');
            window.location.href = '/'
        }
        console.log(err); 
        alert("Something went wrong");
        setLoading(false);
      });
    };
  React.useEffect(() => {
    getAllKycRequests();
  }, []);

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1, lg :1 }}
        spacing={{ base: "20px", xl: "20px" }}> */}
          <Table
            columns={columns}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
            // scroll={{ x: 1500 }}
            rowKey={(record) => record.id}
            scroll={{
              y: 560,
            }}
            size="small"
          />
      {/* </SimpleGrid> */}
    </Box>
  );
}

export default Settings;
