import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  // MdHome,
  // MdLock,
  // MdOutlineShoppingCart,
  MdRequestPage,
  MdListAlt,
  MdSell,
} from "react-icons/md";

// Admin Imports
import DataTables from "views/admin/dataTables";
import KycRequests from "views/admin/kycRequests";
import PurchasedServices from "views/admin/purchasedServices";
import Services from "views/admin/services";
import ResearchDesk from "views/admin/researchDesk";

// Auth Imports
// import SignInCentered from "views/auth/signIn";

const routes = [
  // {
  //   name: "Main Dashboard",
  //   layout: "/admin",
  //   path: "/default",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: MainDashboard,
  // },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "/nft-marketplace",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: NFTMarketplace,
  //   secondary: true,
  // },
  {
    name: "App Users",
    layout: "/admin",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    path: "/app-users",
    component: DataTables,
  },
  {
    name: "KYC Requests",
    layout: "/admin",
    icon: <Icon as={MdRequestPage} width='20px' height='20px' color='inherit' />,
    path: "/kyc-requests",
    component: KycRequests,
  },
  {
    name: "Purchased Services",
    layout: "/admin",
    icon: <Icon as={MdSell} width='20px' height='20px' color='inherit' />,
    path: "/purchased-services",
    component: PurchasedServices,
  },
  {
    name : "Services",
    layout: "/admin",
    icon: <Icon as={MdListAlt} width='20px' height='20px' color='inherit' />,
    path: "/services",
    component: Services
  },
  {
    name: "Research Desk",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/research-desk",
    component: ResearchDesk,
  },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "/profile",
  //   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
  //   component: Profile,
  // },
  // {
  //   // name: "Sign In",
  //   // layout: "/auth",
  //   path: "/sign-in",
  //   // icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
  //   component: SignInCentered,
  // },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "/rtl-default",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: RTL,
  // },
];

export default routes;
