// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { Button, Form, Table,Modal, Input, Select, DatePicker, Space, Popconfirm, message } from 'antd';
import axios from "axios";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { formatDate } from "contexts/dateFormate";


function Settings() {
  var names = [];
  var numbers = [];
  var serviceNames = [];
  const column = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      filterSearch: true,
      filterMultiple: true,
      filters: names
    },
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
      width: 150,
      sorter: (a, b) => a.number - b.number,
      sortDirections: ['descend', 'ascend'],
      onFilter: (value, record) => record.number.indexOf(value) === 0,
      filterSearch: true,
      filterMultiple: true,
      filters: numbers
    },
    {
      title: 'Service Name',
      dataIndex: 'service_name',
      key: 'service_name',
      width: 150,
      sorter: (a, b) => a.service_name.length - b.service_name.length,
      sortDirections: ['descend', 'ascend'],
      onFilter: (value, record) => record.service_name.indexOf(value) === 0,
      filterSearch: true,
      filterMultiple: true,
      filters: serviceNames
      },
      {
      title: 'Service Type',
      dataIndex: 'service_type',
      key: 'service_type',
      width: 150,
      sorter: (a, b) => a.service_type.length - b.service_type.length,
      sortDirections: ['descend', 'ascend'],
      render : (text) => <span>{text.charAt(0).toUpperCase() + text.slice(1)}</span>
      },
      {
      title: 'Service Amount',
      dataIndex: 'service_amount',
      key: 'service_amount',
      width: 150,
      sorter: (a, b) => a.service_amount.length - b.service_amount.length,
      sortDirections: ['descend', 'ascend'],
      },
      {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      width: 150,
      sorter: (a, b) => new Date(a.start_date) - new Date(b.start_date),
      sortDirections: ['descend', 'ascend'],
      render : (text) => <span>{new Date(text).toLocaleDateString()}</span>
      },
      {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      width: 150,
      sorter: (a, b) => new Date(a.end_date) - new Date(b.end_date),
      sortDirections: ['descend', 'ascend'],
      render : (text) => <span>{new Date(text).toLocaleDateString()}</span>
      },
      {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 190,
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      sortDirections: ['descend', 'ascend'],
      // render : (text) => <span>{new Date(text).toLocaleString()}</span>
      render: (text, record) => (
        <span>{formatDate(record.created_at)}</span>
      )
      },
      {
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 120,
        render: (text, record) => (
          <Space size="middle" align="center">
            <a style={{color: 'blue', marginRight: 10, fontSize: 16}} onClick={() => handleEdit(record)} ><EditOutlined /></a>
            <Popconfirm title="Are you sure to delete?" okText="Yes" cancelText="No" loading={loading} onConfirm={() => handleDelete(record.id)}>
            <a style={{color: 'red', fontSize: 16}} ><DeleteOutlined /></a>
            </Popconfirm>
          </Space>
        ),
      }
  ]

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [modal, setModal] = React.useState(false);
  const [modalType, setModalType] = React.useState("");
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 50,
  });
  const [sorter, setSorter] = React.useState({});
  const [filter, setFilter] = React.useState({});
  const [columns, setColumns] = React.useState(column);
  const [serviceList, setServiceList] = React.useState([]);
  const [service_ids, setService_ids] = React.useState({});
  const [recordId, setRecordId] = React.useState(null);
  const dateFormat = "YYYY-MM-DD";

  //create function to get all services name list
  const getAllServicesList = () => {
    axios.get("https://admin.researchvia.online/web/v1/getServicesName", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log("res", res);
        setServiceList(res.data.result.services);
        setService_ids(res.data.result.service_ids);
        res.data.result.services.map((item) => {
          serviceNames.push({text: item, value: item});
        });

      })
      .catch((err) => {
        console.log(err);
        if(err.response.status === 401){
          localStorage.removeItem('token');
            window.location.href = '/'
        }
      });
  };

  const handleDelete = (id) => {
    axios.delete(`https://admin.researchvia.online/web/v1/deletePurchasedService/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log("res", res);
        message.success("Service deleted successfully");
        getAllServicesList();
      })
      .catch((err) => {
        console.log(err);
        if(err.response.status === 401){
          localStorage.removeItem('token');
            window.location.href = '/'
        }
        message.error(err.response.data || "Something went wrong");
      });
  };

  const handleEdit = (record) => {
    record.start_date = dayjs(record.start_date);
    record.end_date = dayjs(record.end_date);
    setRecordId(record.id);
    setModal(true);
    setModalType("edit");
    form.setFieldsValue(record);
  }

  //open modal
  const handleOpen = () => {
    setModal(true);
    setModalType("add");
  };

  // const handleOk = () => {
  //   setConfirmLoading(true);
  //   setTimeout(() => {
  //     setModal(false);
  //     setConfirmLoading(false);
  //   }, 2000);
  // };

  const handleCancel = () => {
    setModal(false);
    setModalType("");
    setConfirmLoading(false);
    form.resetFields();
  };

  const onFinish = (values) => {
    values.start_date = values.start_date.format(dateFormat);
    values.end_date = values.end_date.format(dateFormat);
    values.service_id = service_ids[values.service_name];
    // console.log("Received values of form: ", values);
    setConfirmLoading(true);
    if (modalType === "add") {
      if(values.number.length !== 10){
        form.setFields([
          {
            name: "number",
            errors: ["Phone number must be 10 digits"],
          },
        ]);
        setConfirmLoading(false);
        return;
      }
      axios.post("https://admin.researchvia.online/web/v1/addPurchasedService", values, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          // console.log("res", res);
          setModal(false);
          setConfirmLoading(false);
          getAllPurchasedServices();
          form.resetFields();
        })
        .catch((err) => {
          console.log(err);
          if(err.response.status === 401){
            localStorage.removeItem('token');
              window.location.href = '/'
          }
          alert(err.response.data);
          setConfirmLoading(false);
        });
    }
    if (modalType === "edit") {
      axios.put(`https://admin.researchvia.online/web/v1/editPurchasedService`, {...values, id: recordId}, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          // console.log("res", res);
          setModal(false);
          setConfirmLoading(false);
          getAllPurchasedServices();
          setModalType("");
          form.resetFields();
        })
        .catch((err) => {
          console.log(err);
          if(err.response.status === 401){
            localStorage.removeItem('token');
              window.location.href = '/'
          }
          alert(err.response.data);
          setConfirmLoading(false);
          setModalType("");
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:");
  };
  

  
  const handleTableChange = (pagination, filters, sorter) => {
    // console.log(pagination, filters, sorter);
    setPagination(pagination);
    setSorter(sorter);
    setFilter(filters);
  };

    const getAllPurchasedServices = () => {
        setLoading(true);
    axios.get("https://admin.researchvia.online/web/v1/getPurchaseServices", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log("res", res);
        setData(res.data.result);
        res.data.result.map((item) => {
          names.push({text: item.name, value: item.name});
          numbers.push({text: item.number, value: item.number});
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if(err.response.status === 401){
          localStorage.removeItem('token');
            window.location.href = '/'
        }
        setLoading(false);
      });
    };
  React.useEffect(() => {
    getAllPurchasedServices();
    getAllServicesList();
  }, []);

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1, lg :1 }}
        spacing={{ base: "20px", xl: "20px" }}> */}
        <Button type="primary" onClick={handleOpen}
        style={{marginBottom: '30px'}}
        >
          Add Purchased Service</Button>
            
          <Table
            columns={columns}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
            // scroll={{ x: 1500 }}
            rowKey={(record) => record.id}
            scroll={{
              y: 500,
            }}
            size='small'
          />
          <Modal
            title={modalType === "add" ? "Add Purchased Service" : "Edit Purchased Service"}
            open={modal}
            // onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            //prevent modal from closing when clicked outside modal
            maskClosable={false}
            //prevent modal from closing when clicked on ok button
            // closable={false}
            //remove cancel button
            cancelButtonProps={{ style: { display: "none" } }}
            //remove ok button
            okButtonProps={{ style: { display: "none" } }}
            >
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={{
                modifier: "public",
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[{
                    required: true,
                    message: 'Please enter name!'
                  }]}
              >
                <Input />
              </Form.Item>
              {/* <Form.Item
                name="email"
                label="Email"
                type="email"
                rules={[{
                    required: true,
                    message: 'Please enter email!'
                  }]}
              >
                <Input />
              </Form.Item> */}
              <Form.Item
                name="number"
                label="Number"
                rules={[{
                    required: true,
                    message: 'Please enter number!'
                  }]}
              >
                <Input  placeholder="Enter Number"  type="number"  maxLength={10}/>
              </Form.Item>
              <Form.Item
                name="service_name"
                label="Service Name"
                rules={[{
                    required: true,
                      message: 'Please select service name!'
                  }]}
              >
                <Select>
                  {serviceList.map((item, index) => (
                    <Select.Option value={item} key={index}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="service_type"
                label="Service Type"
                rules={[{
                    required: true,
                    message: 'Please select service type!'
                  }]}
              >
                <Select>
                  <Select.Option value="monthly">Monthly</Select.Option>
                  <Select.Option value="quarterly">Quarterly</Select.Option>
                  <Select.Option value="half-yearly">Half-Yearly</Select.Option>
                  <Select.Option value="yearly">Yearly</Select.Option>
                  <Select.Option value="other">Other</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="service_amount"
                label="Service Amount"
                rules={[{
                    required: true,
                    message: 'Please enter service amount!'
                  }]}
              >
                <Input type="number"/>
              </Form.Item>
              <Space direction="inline" size={40} align="center">
              <Form.Item
                name="start_date"
                label="Start Date"
                rules={[{
                    required: true,
                    message: 'Please select start date!'
                  }]}
              >
                <DatePicker  format={dateFormat}
                // value={dayjs().format(dateFormat)}
                 />
              </Form.Item>
              <Form.Item
                name="end_date"
                label="End Date"
                rules={[{
                    required: true,
                    message: 'Please select end date!'
                  }]}
              >
                <DatePicker  format={dateFormat}
                // value={dayjs().format(dateFormat)}
                 />
              </Form.Item>
              </Space>
              {/* <Form.Item
                name="end_date"
                label="End Date"
                rules={[
                  {
                    required: true,
                    message: 'Please select end date!'
                  }
                ]}
              >
                <DatePicker />
              </Form.Item> */}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={confirmLoading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Modal>
      {/* </SimpleGrid> */}
    </Box>
  );
}

export default Settings;