export function formatDate(date) {
  const options = {
    timeZone: "Asia/Kolkata",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  const formattedDate = new Date(date).toLocaleString("en-US", options);

  const [month, day, yearAndTime] = formattedDate.split("/");
  const [year, time] = yearAndTime.trim().split(",");

  return `${day}/${month}/${year} ${time}`;
}
