// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { Table, Modal, Form, Input, Button } from 'antd';
import axios from "axios";
import { formatDate } from "contexts/dateFormate";



function Settings() {
  var names = [];
  var numbers = [];
  const column = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      filterSearch: true,
      filterMultiple: true,
      filters: names
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 150,
      // fixed: 'left',
    },
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
      width: 150,
      sorter: (a, b) => a.number - b.number,
      sortDirections: ['descend', 'ascend'],
      onFilter: (value, record) => record.number.indexOf(value) === 0,
      filterSearch: true,
      filterMultiple: true,
      filters: numbers
    },
    {
      title: 'KYC Status',
      dataIndex: 'kyc_status',
      key: 'kyc_status',
      width: 150,
      sorter: (a, b) => a.kyc_status.length - b.kyc_status.length,
      sortDirections: ['descend', 'ascend'],
      //render it with button and change the color of button according to status
      render: (text) => <span style={{color: text === "KYC Verified" ? "green" : text === "KYC Pending" ? "orange" : text === "KYC Rejected" ? "red" : "yello", fontWeight: 'bold', fontSize: 14}}>{text}</span>
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 150,
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      sortDirections: ['descend', 'ascend'],
      // render : (text) => <span>{new Date(text).toLocaleString()}</span>
      render: (text, record) => (
        <span>{formatDate(record.created_at)}</span>
      ),
    },
    {
      title: 'Support Number',
      dataIndex: 'support_number',
      key: 'support_number',
      width: 150,
      sorter: (a, b) => a.support_number - b.support_number,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 150,
      fixed: 'right',
      render: (text, record) => (
          <span>
              <a style={{ marginRight: 16, color: 'blue' }} onClick={() => handleEdit(record.id)}>Add Support Number</a>
          </span>
          ),
    },
  ]
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 50,
  });
  const [sorter, setSorter] = React.useState({});
  const [filter, setFilter] = React.useState({});
  const [columns, setColumns] = React.useState(column);
  const [modal, setModal] = React.useState(false);
  const [modalType, setModalType] = React.useState("");
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [editId, setEditId] = React.useState(null);

  const handleCancel = () => {
    setModal(false);
    setModalType("");
    setConfirmLoading(false);
    // form.resetFields();
  };

  const onFinish = (values) => {
    // console.log("Success:", values);
    if(values.support_number.length !== 10){
      form.setFields([{
          name: 'support_number',
          errors: ['Please enter 10 digit number'],
        }]);
      setConfirmLoading(false);
      return;
    }
    values.id = editId;
    setConfirmLoading(true);
    axios.post("https://admin.researchvia.online/web/v1/addSupportNumber", values,
        { headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },})
      .then((res) => {
        // console.log("res", res);
        setModal(false);
        setConfirmLoading(false);
        setModalType("");
        getAppUsers();
        form.resetFields();
      })
      .catch((err) => {
        console.log(err);
        if(err.response.status === 401){
          localStorage.removeItem('token');
            window.location.href = '/'
        }
        alert(err.response.data.message);
        setConfirmLoading(false);
        setModal(false);
        setModalType("");
        form.resetFields();
      });
  };



  const handleEdit = (id) => {
    // console.log(id);
    setModal(true);
    setEditId(id);
  }
  
  const handleTableChange = (pagination, filters, sorter) => {
    // console.log(pagination, filters, sorter);
    setPagination(pagination);
    setSorter(sorter);
    setFilter(filters);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:");
  };

  const getAppUsers = () => {
    setLoading(true);
    axios
      .get("https://admin.researchvia.online/web/v1/getAppUsers", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log("res", res);
        setData(res.data.result);
        //create names fileter array
        res.data.result.forEach((item) => {
          names.push({text : item.name, value : item.name});
          numbers.push({text : item.number, value : item.number});
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err); 
        if(err.response.status === 401){
          localStorage.removeItem('token');
            window.location.href = '/'
        }
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getAppUsers();
  }, []);

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1, lg :1 }}
        spacing={{ base: "20px", xl: "20px" }}> */}
          <Table
            columns={columns}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
            // scroll={{ x: 1500 }}
            rowKey={(record) => record.id}
            scroll={{
              y: 560,
            }}
            size="small"
          />
          <Modal
            title="Add New Service"
            open={modal}
            // onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            //prevent modal from closing when clicked outside modal
            maskClosable={false}
            //prevent modal from closing when clicked on ok button
            // closable={false}
            //remove cancel button
            cancelButtonProps={{ style: { display: "none" } }}
            //remove ok button
            okButtonProps={{ style: { display: "none" } }}
            >
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={{
                modifier: "public",
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="support_number"
                label="Support Number"
                rules={[
                  {
                    required: true,
                    message: "Please input your support number!",
                  },
                ]}
              >
                <Input placeholder="Enter support number" type="number" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={confirmLoading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
            </Modal>
      {/* </SimpleGrid> */}
    </Box>
  );
}

export default Settings;
