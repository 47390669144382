// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { Button, Form, Table,Modal, Input, Select, message, Popconfirm, Space } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from "axios";
import { formatDate } from "contexts/dateFormate";
const { TextArea } = Input;


function Settings() {
  const serviceNames = [];
  const column = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      filterSearch: true,
      filterMultiple: true,
      filters: serviceNames
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 250,
      sorter: (a, b) => a.description.length - b.description.length,
      sortDirections: ['descend', 'ascend'],
      fixed: 'left',
      elipsis: true,
    },
    {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 120,
    sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (
      <span>{formatDate(record.created_at)}</span>
    )
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 120,
      render: (text, record) => (
        <Space size="middle" align="center">
          <a style={{color: 'blue', marginRight: 10, fontSize: 16}} onClick={() => handleEdit(record)} ><EditOutlined /></a>
          <Popconfirm title="Are you sure to delete?" okText="Yes" cancelText="No" loading={loading} onConfirm={() => handleDelete(record.id)}>
          <a style={{color: 'red', fontSize: 16}} ><DeleteOutlined /></a>
          </Popconfirm>
        </Space>
      ),
    }
  ]
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [modalType, setModalType] = React.useState("");
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 50,
  });
  const [sorter, setSorter] = React.useState({});
  const [filter, setFilter] = React.useState({});
  const [columns, setColumns] = React.useState(column);
  const [serviceList, setServiceList] = React.useState([]);
  const [service_ids, setService_ids] = React.useState({});
  const [recordId, setRecordId] = React.useState(null);
  const dateFormat = "YYYY-MM-DD";

  //create function to get all services name list
  const getAllServicesList = () => {
    axios.get("https://admin.researchvia.online/web/v1/getServicesName", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log("res", res);
        setServiceList(res.data.result.services);
        setService_ids(res.data.result.service_ids);
        res.data.result.services.map((item) => {
          serviceNames.push({text: item, value: item});
        });
      })
      .catch((err) => {
        console.log(err);
        if(err.response.status === 401){
          localStorage.removeItem('token');
            window.location.href = '/'
        }
      });
  };


  const handleDelete = (id) => {
    axios.delete(`https://admin.researchvia.online/web/v1/deleteResearch/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log("res", res);
        message.success("Research deleted successfully");
        getAllResearchDesk();
      })
      .catch((err) => {
        console.log(err);
        if(err.response.status === 401){
          localStorage.removeItem('token');
            window.location.href = '/'
        }
        message.error(err.response.data || "Something went wrong");
      });
  };

  const handleEdit = (record) => {
    setRecordId(record.id);
    setModal(true);
    setModalType("edit");
    form.setFieldsValue(record);
  };
  //open modal
  const handleOpen = () => {
    setModal(true);
    setModalType("add");
  };

  // const handleOk = () => {
  //   setConfirmLoading(true);
  //   setTimeout(() => {
  //     setModal(false);
  //     setConfirmLoading(false);
  //   }, 2000);
  // };

  const handleCancel = () => {
    setModal(false);
    setConfirmLoading(false);
  };

  const onFinish = (values) => {
    values.service_id = service_ids[values.name];
    // console.log("Received values of form: ", values);
    setConfirmLoading(true);
    if (modalType === "add") {
      axios.post("https://admin.researchvia.online/web/v1/addResearch", values, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
        //   console.log("res", res);
          setModal(false);
          setConfirmLoading(false);
          getAllResearchDesk();
          form.resetFields();
        })
        .catch((err) => {
          console.log(err);
          if(err.response.status === 401){
            localStorage.removeItem('token');
              window.location.href = '/'
          }
          alert(err.response.data);
          setConfirmLoading(false);
          setModalType("");
        });
    }
    if (modalType === "edit") {
      axios.put(`https://admin.researchvia.online/web/v1/editResearch`, {...values, id: recordId}, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
        //   console.log("res", res);
          setModal(false);
          setConfirmLoading(false);
          getAllResearchDesk();
          form.resetFields();
          setModalType("");
        })
        .catch((err) => {
          console.log(err);
          if(err.response.status === 401){
            localStorage.removeItem('token');
              window.location.href = '/'
          }
          alert(err.response.data);
          setConfirmLoading(false);
          setModalType("");
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  

  
  const handleTableChange = (pagination, filters, sorter) => {
    // console.log(pagination, filters, sorter);
    setPagination(pagination);
    setSorter(sorter);
    setFilter(filters);
  };

    const getAllResearchDesk = () => {
        setLoading(true);
    axios.get("https://admin.researchvia.online/web/v1/getResearches", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        // console.log("res", res);
        setData(res.data.result);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err); 
        if(err.response.status === 401){
          localStorage.removeItem('token');
            window.location.href = '/'
        }
        setLoading(false);
      });
    };
  React.useEffect(() => {
    getAllResearchDesk();
    getAllServicesList();
  }, []);

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1, lg :1 }}
        spacing={{ base: "20px", xl: "20px" }}> */}
        <Button type="primary" onClick={handleOpen}
        style={{marginBottom: '30px'}}>
          Add New Researchs
          </Button>
            
          <Table
            columns={columns}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
            // scroll={{ x: 1500 }}
            rowKey={(record) => record.id}
            scroll={{
              y: 500,
            }}
            size="small"
          />
          <Modal
            title="Add New Research"
            open={modal}
            // onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            //prevent modal from closing when clicked outside modal
            maskClosable={false}
            //prevent modal from closing when clicked on ok button
            // closable={false}
            //remove cancel button
            cancelButtonProps={{ style: { display: "none" } }}
            //remove ok button
            okButtonProps={{ style: { display: "none" } }}
            >
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              initialValues={{
                modifier: "public",
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
             <Form.Item
                name="name"
                label="Research Name"
                rules={[{
                    required: true,
                      message: 'Please select name!'
                  }]}
              >
                <Select>
                  {serviceList.map((item, index) => (
                    <Select.Option value={item} key={index}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                rules={[{
                    required: true,
                    message: 'Please enter service description!'
                  }]}
              >
                <TextArea placeholder="Enter Description" showCount maxLength={400} style={{height: 150, resize: 'none'}} />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={confirmLoading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Modal>
      {/* </SimpleGrid> */}
    </Box>
  );
}

export default Settings;
